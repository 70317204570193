import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image";
import { Box } from '@sqymagma/elements'
import { useStaticQuery, graphql } from "gatsby";
import { theme } from '../utils/themeGet'
import DownloadButton from "../components/DownloadButton";
import SEO from "../components/Seo";


const GalleryPage = ({ data }) => {
	const carouselImages = useStaticQuery(graphql`
	query {
		data: allFile(filter: {relativeDirectory: {eq: "carousel"}}) {
			edges {
                 node {
                    name
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1600) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64

                        }
                    }
                 }
            }
        }
    }`);

  return (
   <>
    <SEO
        title="No te confies"
    />
    <Box css={`{
        .carousel-root {
           display: flex;
           flex-direction: column-reverse;
           background: ${theme('color.ui07')};
        }

        .carousel {
            border-top:1px solid ${theme('color.ui07')};
            position: relative; 

            .thumbs-wrapper {
                margin: 12px;
            }
            li.thumb {
                height: 80px;
                padding: 0;
                border: none;
                opacity: .6;

                &.selected {
                    border: 1px solid #fff;
                    opacity: 1;
                }
            }
        }
   }`}>
        <Carousel 
            showArrows={false} dynamicHeight={true} showThumbs={true} showStatus={false}
            swipeable={true} renderThumbs={customRenderThumb}
            renderItem={customRenderItem}
        >
            {carouselImages.data.edges.map((item, idx) => (
                <div key={idx}>
                    <Img fluid={item.node.childImageSharp.fluid} name={item.node.name}/>
                </div>
            ))}
        </Carousel>
   </Box>
   </>
    )
};

export default GalleryPage;


const ParallaxButton = ({ src }) => {
  
    return (
      <Box position="absolute" top="30px" right="30px">
        <DownloadButton file={`no-te-confies/${src}.png`}/>
      </Box>
    );
  };

  const customRenderThumb = (children) =>
  children.map((item, idx) => {
      const imageData = item.props.children.props.fluid;
      return (
          <BackgroundImage Tag="section" fluid={imageData}
          key={idx}
          style={{ 
              backgroundSize: "cover", 
              backgroundPosition: "center", 
              position: "relative",
              width: "100px",
              height: "100px"
          }}
          />
      );
  });

  const customRenderItem = (item, props) => {
      return(
         <>
          <item.type {...item.props} {...props} />    
          {props.isSelected && <ParallaxButton zIndex="2" position="fixed" top="100px" right="24px" src={item.props.children.props.name}/>}
         </>
      )
  }


